export const tableHeaders = [
  "CRN ID",
  "Name",
  "phone",
  "Grade",
  "Login ID",
  "reg source",
  "Registered On",
  //"Onboarded",
  "Last Login",
  // "Score Attempt",
  // "Olympiad Attempt",
];

export const title = ["Insights"];
